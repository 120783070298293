import foto1 from "../../assets/galeria/foto1.jpg";
import foto2 from "../../assets/galeria/foto2.jpg";
import foto3 from "../../assets/galeria/foto3.jpg";
import foto4 from "../../assets/galeria/foto4.jpg";
import foto5 from "../../assets/galeria/foto5.jpg";
import foto6 from "../../assets/galeria/foto6.jpg";

const galeria = [
  {
    alt: "",
    url: foto1,
  },
  {
    alt: "",
    url: foto2,
  },
  {
    alt: "",
    url: foto3,
  },
  {
    alt: "",
    url: foto4,
  },
  {
    alt: "",
    url: foto5,
  },
  {
    alt: "",
    url: foto6,
  },
];

export default galeria;
