import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Routes from './shared/routes'


export default function App() {
  return (
    <Routes>
    </Routes>
  );
}
